import { motion } from "framer-motion";
import { useState } from "react";
import "./Experience.scss";

import { itemAnimation } from "../animations/itemAnimations";
import Title from "../components/Title";

function Experience() {
  return (
    <div id="experience">
      <main>
        <Title>EXPERIENCE</Title>
        <section>
          <ExpItem
            title={"Dixie Bot"}
            description={
              "Dixie Bot is a Discord bot, for this project my part was developing Front-End and working directly on bot commands. For Frone-End I used React, Typescript, Tailwind, saas, framer motion and axios. For Back-end I used NodeJS, Express, Discord.JS, JWT and MongoDB & Redis"
            }
            role={"App Development and Front-end"}
            company={"Personal project"}
            date={"June/2024 - On-going"}
            index={1}
          />
          <ExpItem
            title={"Clickpulse"}
            description={
              "Clickpulse is a analytics platform that provides real-time insight for optimising website performance. For this project my part was designing and developing Front-End. For this project I used React, Typescript, Tailwind, saas, axios, recharts and framer motion."
            }
            role={"App Development and Front-end"}
            company={"Personal project"}
            date={"July/2024 - October/2024"}
            index={1}
          />
        </section>
      </main>
    </div>
  );
}

function ExpItem({
  title,
  description,
  techStack,
  role,
  company,
  date,
  index,
}) {
  const [toggleDescription, setToggleDescription] = useState("hide");

  function toggle() {
    setToggleDescription(toggleDescription === "hide" ? "" : "hide");
  }

  return (
    <motion.article
      variants={itemAnimation}
      initial="initial"
      whileInView="animate"
      custom={{ index: index, time: 0.2 }}
      viewport={{ once: true, amount: 0.5 }}
    >
      <div className={`title border-${toggleDescription}`}>
        <div className="content">
          <p>{title}</p>
          <h2>{role}</h2>
          <h3>{company}</h3>
          <p className="date">{date}</p>
        </div>
        <div className="more">
          <i
            className={`fas fa-chevron-right arrow-${toggleDescription}`}
            onClick={() => {
              toggle();
            }}
          ></i>
        </div>
      </div>
      <div className={`description ${toggleDescription}`}>
        <p>{description}</p>
      </div>
    </motion.article>
  );
}

export default Experience;
